<template>
    <div>
        Please wait...
    </div>
</template>
<script>
    import {AuthStateModel} from "../models/state/AuthStateModel";
    import {PKCEMixin} from "../mixins/PKCEMixin";

    import {apiClient} from "../helpers/apiClient";
    import {ClaimsResponse} from "../models/response/ClaimsResponse";
    import {HttpError} from "../models/error/HttpError";
    import {debugLog} from "../helpers/debugLog";
    import moment from 'moment'
    import {setRefreshTokenState} from "../helpers/handleRefreshToken";
    import {RefreshStateModel} from "../models/state/RefreshStateModel";

    export default {
        name: "LoginCallback",
        mixins: [PKCEMixin],
        data() {
            return {
                isError: false,
                error: null
            }
        },
        mounted() {
            if (!window.localStorage.getItem('state')) {
                this.$router.push({
                    name: 'Login'
                });
                return;
            }

            let data: AuthStateModel = JSON.parse(atob(window.localStorage.getItem('state')));
            if (data.state !== this.$route.query.state) {
                window.localStorage.removeItem('state');
                this.$router.push({
                    name: 'Login'
                });
                return;
            }

            let tokenUrl = `${process.env.VUE_APP_GRO_AUTH_URL}/connect/token`;

            let params = {
                grant_type: 'authorization_code',
                client_id: process.env.VUE_APP_GRO_CLIENT_ID,
                code_verifier: data.verifier,
                code: this.$route.query.code,
                redirect_uri: process.env.VUE_APP_GRO_CLIENT_REDIRECT_URI
            };

            fetch(tokenUrl, {
                method: 'POST',
                body: this.serialize(params),
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then((res) => {
                if (res.status === 200) {
                    return res.json();
                } else {
                    return res.json().then((json) => {
                        throw new HttpError(res.status, json);
                    });
                }
            }).then((data) => {
                //no longer need the state
                window.localStorage.removeItem('state');

                const {access_token, refresh_token, expires_in} = data;

                setRefreshTokenState(new RefreshStateModel(access_token, refresh_token, moment().unix(), expires_in))

                let claims: ClaimsResponse = this.$store.getters['Auth/getClaims'];

                if (!['super', 'prePaymentMember'].some(x => claims.role.includes(x))) {
                    /** User does not include role. **/
                    /** Clear refresh token state from store **/
                    setRefreshTokenState(new RefreshStateModel());

                    this.$router.push({name: 'Login'});

                    return;
                }

                //get the coach
                this.$store.dispatch('User/getUser');

                this.$router.push({
                    name: 'portal.referrals'
                })
            }).catch((err: HttpError) => {

                debugLog(`Gro Login Error`, err);

                if (err.status === 400) {

                    return this.$router.push({name: 'Login'});
                }
            });
        }
    }
</script>